const meta = {
  investing: {
    title: 'Investing',
    type: 'page'
  },
  guides: {
    title: 'Trading bot',
    type: 'page'
  },
  blog: {
    type: 'page',
    title: 'Blog',
    theme: {
      sidebar: false,
      toc: true,
      breadcrumb: false,
      pagination: false,
    },
  },
  tools: {
    title: 'Tools',
    type: 'menu',
    items: {
      'chatgpt-trading': {
        title: 'ChatGPT trading',
        href: '/tools/crypto-prediction',
      },
      'triangular-arbitrage': {
        title: 'Triangular arbitrage',
        href: '/tools/triangular-arbitrage-crypto',
      },
      'scalping-trading': {
        title: 'Scalping signals',
        href: '/tools/scalping-signals',
      },
    },
  },
  about: {
    type: 'page',
    title: 'About us',
    theme: {
      sidebar: false,
      toc: false,
      breadcrumb: false,
      pagination: false,
    },
  },
}

export default meta
