const meta = {
  profiles: 'Profiles',
  'profile-configuration': 'Profile configuration',
  'cloud-strategy-in-open-source-and-pro': 'OctoBot cloud Strategies',
  accounts: 'Accounts',
  'custom-profile': 'Custom profile',
  exchanges: 'Exchanges',
  'premium-octobot-extension': 'Premium extension',
}
export default meta
