const meta = {
  '--- 1': {
    title: 'Setup',
    type: 'separator',
  },
  'setup-your-environment': 'Setup your environment',
  'running-tests': 'Runing tests',
  '--- 2': {
    title: 'The environment',
    type: 'separator',
  },
  architecture: "OctoBot's architecture",
  'github-repositories': 'GitHub repositories',
  tips: 'Developer tips',
  'environment-variables': 'Environment variables',
}

export default meta
