const meta = {
  binance: 'Binance',
  okx: 'OKX',
  kucoin: 'Kucoin',
  bybit: 'Bybit',
  'crypto-com': 'Crypto.com',
  htx: 'HTX',
  bitget: 'Bitget',
  bingx: 'BingX',
  mexc: 'MEXC',
  coinex: 'CoinEx',
  bitmart: 'BitMart',
  hollaex: 'HollaEx',
  phemex: 'Phemex',
  gateio: 'GateIO',
  ascendex: 'Ascendex',
  okcoin: 'Okcoin',
}
export default meta
