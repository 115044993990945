const meta = {
  '--- 1': {
    title: 'Officially supported',
    type: 'separator',
  },
  binanceus: 'Binance.us',
  coinbase: 'Coinbase',
  '--- a2': {
    title: 'Community tested',
    type: 'separator',
  },
  bitfinex: 'Bitfinex',
  bitstamp: 'Bitstamp',
  bittrex: 'Bittrex',
  bitvavo: 'Bitvavo',
  hitbtc: 'HitBTC',
  kraken: 'Kraken',
  poloniex: 'Poloniex',
  wavesexchange: 'Wavesexchange',
}
export default meta
