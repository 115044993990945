const meta = {
  '--- 1': {
    title: 'Introduction',
    type: 'separator',
  },
  'customize-your-octobot': 'Customize your OctoBot',
  '--- 2': {
    title: 'Development',
    type: 'separator',
  },
  'create-a-tentacle': 'Create a tentacle',
  'create-a-tentacle-package': 'Create a tentacle package',
}
export default meta
