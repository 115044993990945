const meta = {
  '--- 1': {
    title: 'Trading strategies',
    type: 'separator',
  },
  strategies: 'Strategies',
  '--- 2': {
    title: 'Trading keywords',
    type: 'separator',
  },
  'creating-trading-orders': 'Creating orders',
  '--- 3': {
    title: 'Results',
    type: 'separator',
  },
  'run-report': 'Run report',
  'plotting-indicators': 'Plotting indicators',
  'plotting-anything': 'Plotting anything',
  '--- 4': {
    title: 'Trading data',
    type: 'separator',
  },
  'fetching-history': 'Historical data',
}
export default meta
