const meta = {
  'trading-modes': 'Trading modes',
  'dca-trading-mode': 'DCA trading mode',
  'index-trading-mode': 'Index trading mode',
  'daily-trading-mode': 'Daily trading mode',
  'chatgpt-trading': 'ChatGPT trading',
  'dip-analyser-trading-mode': 'Dip Analyser trading mode',
  'grid-trading-mode': 'Grid trading mode',
  'staggered-orders-trading-mode': 'Staggered Orders trading mode',
  'tradingview-trading-mode': 'TradingView trading mode',
  'order-amount-syntax': 'Orders amount syntax',
  'order-price-syntax': 'Orders price syntax',
}
export default meta
