const meta = {
  'simulator': 'Simulator',
  'futures-trading-with-octobot': 'Futures trading', 
  'backtesting': 'Backtesting',
  'strategy-designer': "Strategy Designer",
  'having-multiple-octobots-on-one-computer': "Having multiple OctoBots",
  'understanding-profitability': 'Understanding profitability',
  'frequently-asked-questions-faq': 'FAQ',
}
export default meta
