const meta = {
  'introduction': 'Introduction',
  '--- 4': {
    title: 'Invest',
    type: 'separator',
  },
  'find-your-strategy': 'Find your investment',
  'paper-trading-a-strategy': 'Test risk-free',
  'invest-with-your-strategy': 'Start to invest',
  'having-multiple-octobot-strategies': 'Having multiple OctoBots',
  'stop-a-strategy': 'Stop investing',
  '--- 5': {
    title: 'Follow your investments',
    type: 'separator',
  },
  'follow-your-profits': 'Follow your profits',
  'fine-tune-your-octobots': 'Fine tune your OctoBot',
  '--- 6': {
    title: 'TradingView strategies',
    type: 'separator',
  },
  'tradingview-automated-trading': 'TradingView automated trading',
  'tradingview-trading-tutorial': 'TradingView trading tutorial',
  'tradingview-alerts-automation': 'TradingView alerts automation',
  'how-to-automate-any-tradingview-strategy-on-octobot-cloud': 'Strategy automation tutorial',
  'tradingview-strategies-tutorials': 'Strategy examples',
  '--- 7': {
    title: 'Exchanges',
    type: 'separator',
  },
  'connect-your-binance-account-to-octobot': 'Connecting to Binance',
  'connect-your-kucoin-account-to-octobot': 'Connecting to Kucoin',
  'connect-your-coinbase-account-to-octobot': 'Connecting to Coinbase',
  '--- 8': {
    title: "Subscriptions",
    type: 'separator',
  },
  'pay-with-crypto': 'Pay with crypto',
  '--- 9': {
    title: 'Questions',
    type: 'separator',
  },
  'investor-faq': 'FAQ',
  'what-is-an-exchange-api-key': 'What are API Keys ?',
}

export default meta
