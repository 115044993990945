const meta = {
  octobot: 'Starting your OctoBot',
  '--- 2': {
    title: 'OctoBot',
    type: 'separator',
  },
  'octobot-installation': 'Installation',
  'octobot-configuration': 'Configuration',
  'octobot-trading-modes': 'Trading Modes',
  'octobot-usage': 'Usage',
  'octobot-interfaces': 'Interfaces',
  'octobot-advanced-usage': 'Advanced usage',
  '--- 3': {
    title: 'Supported exchanges',
    type: 'separator',
  },
  exchanges: 'Summary',
  'octobot-partner-exchanges': 'Partner exchanges',
  'octobot-supported-exchanges': 'Supported exchanges',
  '--- 4': {
    title: 'OctoBot script',
    type: 'separator',
  },
  'octobot-script': 'Start scripting',
  'octobot-script-docs': 'OctoBot script docs',
  '--- 5': {
    title: 'For developers',
    type: 'separator',
  },
  developers: 'Starting as a developer',
  'octobot-developers-environment': 'Developer environment',
  'octobot-tentacles-development': 'Tentacles development',
}

export default meta
