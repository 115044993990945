const meta = {
  'install-octobot-on-your-computer': 'Local installation',
  'cloud-install-octobot-on-digitalocean': 'In the cloud',
  'install-octobot-with-executable-video': 'With Executable',
  'install-octobot-with-docker-video': 'With Docker',
  'install-octobot-on-raspberry-pi': 'With Raspberry Pi',
  'install-octobot-with-python-and-git': 'With Python and Git',
  'install-octobot-with-pip': 'With PIP',
  'octobot-troubleshoot': 'Troubleshoot',
}

export default meta
